import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import Layout from '../../components/layout';
import Transition from '../../components/transition';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { InitTransition } from '../../components/init-transition';
import InitAnimation from '../../components/init-animation';
import Style from '../../styles/services/services.module.scss';

class Design extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
    this.initAnimation = new InitAnimation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const isDesign = 'Design';
    const headingDesign = document.querySelector('.init_a_headingDesign');
    const introServices = document.querySelector('.init_a_introServices');
    setTimeout(() => {
      this.initAnimation.heading(headingDesign, isDesign);
      this.initAnimation.intro(introServices);
    }, 1000);
  }

  serviceList = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const serviceItem = document.querySelectorAll('.init_a_serviceItem');
      this.initAnimation.list(serviceItem);
      unobserve();
    }
  };

  serviceSkill = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const strategySkill = document.querySelector('.init_a_serviceSkill');
      this.initAnimation.list(strategySkill);
      unobserve();
    }
  };

  render() {
    const DesignQuery = graphql`
      {
        illustration: file(relativePath: { eq: "illustration/services_design.png" }){
          childImageSharp {
            fluid(maxWidth: 703, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        badge: file(relativePath: { eq: "illustration/icon_design.png" }){
          childImageSharp {
            fluid(maxWidth: 280, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
      }
    `;

    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    const options = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    };

    const optionsSkill = {
      root: null,
      rootMargin: '-50px',
      threshold: 0,
    };

    return (
      <StaticQuery
        query={DesignQuery}
        render={(data) => (
          <Layout>
            <Transition ref={this.child}>
              <Header transition={transition} current="services" />
              <ParallaxProvider>
                <main className={`services ${Style.services}`}>
                  <section className={`top ${Style.top}`}>
                    <div className={Style.left}>
                      <h1 className="heading">
                        <span className="text textDesign">Design</span>
                        <span className="clip clipDesign init_a_headingDesign">Design</span>
                      </h1>
                      <p className={`intro init_a_introServices ${Style.intro}`}>雑誌や書籍、ポスター等の紙媒体から、大小様々な規模のウェブやアプリまで、施策に合わせた最適なデザインを提案します。提供された情報をそのまま形にするのではなく、再構築した上で、デザインアイデアを生み出します。</p>
                    </div>
                    <Parallax className={`${Style.illustration} ${Style.illustrationDesign}`} x={[15, -15]} tagInner="figure">
                      <Img
                        className={Style.illustrationImg}
                        fluid={data.illustration.childImageSharp.fluid}
                        alt="Design"
                      />
                    </Parallax>
                  </section>
                  <Observer {...options} onChange={this.serviceList}>
                    <section className={`sectionContent sectionContentFirst ${Style.service}`}>
                      <div className="scroll scrollTop" />
                      <div className={`inner ${Style.inner}`}>
                        <dl className={`init_a_serviceItem ${Style.serviceItem}`}>
                          <dt className={Style.serviceItemHeading}>
                            媒体を横断する
                            <br />
                            展開力
                          </dt>
                          <dd className={Style.serviceItemDescription}>紙媒体からウェブ媒体まで幅広いメディアを取り扱うため、包括的なデザインコントロールを得意としています。また、各種メディアのトレンドを理解した上で、一貫したデザインのトーン、クオリティのコントロールを心がけ、プロジェクトに応じた最適なデザインをワンストップで提案することが可能です。</dd>
                        </dl>
                        <dl className={`init_a_serviceItem ${Style.serviceItem}`}>
                          <dt className={Style.serviceItemHeading}>
                            情報整理と
                            <br />
                            再解釈
                          </dt>
                          <dd className={Style.serviceItemDescription}>与えられた情報をただ配置するのではなく、ユーザーの行動や価値観から原理的に解釈し、伝えたい情報の優先順位を整理した上でデザインします。また、散らばった情報やイメージをグラフィック表現として再解釈し、新しい価値や気付きを与える創発的な役割を担ったデザイン・アプローチを重視しています。</dd>
                        </dl>
                        <dl className={`init_a_serviceItem ${Style.serviceItem}`}>
                          <dt className={Style.serviceItemHeading}>
                            「動き」を備えた
                            <br />
                            UIデザイン
                          </dt>
                          <dd className={Style.serviceItemDescription}>ウェブサイトやアプリの制作において、ユーザビリティやファンダビリティ、アクセシビリティに配慮したインターフェイスを心がけています。また、タップやクリックによる「動き」のデザインが、無意識下のうちに行動や感情に影響を与えるため、インタラクションデザインの観点からも設計を行なっています。</dd>
                        </dl>
                      </div>
                    </section>
                  </Observer>
                  <Observer {...optionsSkill} onChange={this.serviceSkill}>
                    <section className={`sectionContent ${Style.skillup}`}>
                      <div className={`inner init_a_serviceSkill ${Style.inner}`}>
                        <div className={Style.skillupIllustration}>
                          <Img
                            className={Style.illustrationImg}
                            fluid={data.badge.childImageSharp.fluid}
                            alt="Mediagrowth"
                          />
                        </div>
                        <div className={Style.right}>
                          <h4>パートナーとの共犯関係</h4>
                          <p>デザイナー優位の置いてけぼりのデザインではなく、パートナーに寄り添って、共感できるポイントを一緒に探しながらデザインを創りあげていきます。大切なものは残しながらも、新しい技術を取り入れたデザインを提案します。</p>
                        </div>
                      </div>
                    </section>
                  </Observer>
                </main>
              </ParallaxProvider>
              <Footer transition={transition} />
            </Transition>
          </Layout>
        )}
      />
    );
  }
}

export default Design;
